import React from "react";
import { useState, useEffect } from "react";
import TopHeader from "../../../Components/TopHeader";
import Prince from "../../../Components/Prince";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import spiner from "../../../assets/img/spiner.gif";
import { MdDateRange } from "react-icons/md";
import FilterComponent from "../ReportComponent/FilterComponent";
import CardComponent from "../ReportComponent/CardComponent";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { toast } from "react-toastify";

function ClosureReport({
  handleApplyFilter,
  handleConfirm,
  setFilterByObj,
  filterByObj,
}) {
  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");

  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [modelOpen, setModalOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [counts, setCounts] = useState([]);
  const [wonLeadBySource, setWonLeadBySource] = useState([]);
  const [wonLeadByStage, setWonLeadByStage] = useState([]);
  const [wonLeadByState, setWonLeadByState] = useState([]);
  const [wonleadByPerSalesAgent, setWonLeadByPerSalesAgent] = useState([]);
  const [wonVsLost, setWonVsLost] = useState([]);

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    // setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleDateChange1 = (ranges) => {
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    setFilterByObj({
      ...filterByObj,
      to: adjustedEndDate.toISOString(),
      from: adjustedStartDate.toISOString(),
    });

    setDate(ranges.selection);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        };
        const response = await fetch(`${apiUrl}/closureReport/headerCount`, {
          method: "GET",
          headers: headers,
        });
        const result = await response.json();
        setCounts(result.data);
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        };
        const response = await fetch(
          `${apiUrl}/closureReport/wonLeadCountSource`,
          {
            method: "GET",
            headers: headers,
          }
        );
        const result = await response.json();
        setWonLeadBySource(result.data);
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        };
        const response = await fetch(
          `${apiUrl}/closureReport/wonLeadCountStage`,
          {
            method: "GET",
            headers: headers,
          }
        );
        const result = await response.json();
        setWonLeadByStage(result.data);
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        };
        const response = await fetch(
          `${apiUrl}/closureReport/wonLeadCountState`,
          {
            method: "GET",
            headers: headers,
          }
        );
        const result = await response.json();
        setWonLeadByState(result.data);
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        };
        const response = await fetch(
          `${apiUrl}/closureReport/wonLeadPerSalesAgent`,
          {
            method: "GET",
            headers: headers,
          }
        );
        const result = await response.json();
        setWonLeadByPerSalesAgent(result.data);
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        };
        const response = await fetch(`${apiUrl}/closureReport/wonVsLost`, {
          method: "GET",
          headers: headers,
        });
        const result = await response.json();
        setWonVsLost(result.data);
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />
        <div className="main-content side-content pt-0 m-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="page-header my-2">
                <div>
                  <h2 className="main-content-title tx-24 mb-0 mt-1 ">
                    Closure Report
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Report</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Closure Report
                    </li>
                  </ol>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div className="row">
                  <CardComponent
                    title={"Total Won Lead Daily"}
                    link={"Won Lead Daily"}
                    count={counts.dailyWonLeadCount}
                  />
                  <CardComponent
                    title={"Total Won Lead Weekly"}
                    link={"Won Lead Weekly"}
                    count={counts.weeklyWonLeadCount}
                  />
                  <CardComponent
                    title={"Total Won Lead Monthly"}
                    link={"Won Lead Monthly"}
                    count={counts.monthlyWonLeadCount}
                  />
                  <CardComponent
                    title={"Total Won Lead Quarterly"}
                    link={"Won Lead Quarterly"}
                    count={counts.quaterlyWonLeadCount}
                  />
                  <CardComponent
                    title={"Total Won Lead Yearly"}
                    link={"Won Lead Yearly"}
                    count={counts.yearlyWonLeadCount}
                  />

                  <div className="card custom-card">
                    <div className="card-body py-0 pb-3">
                      <div className="row">
                        <div className="col-sm-2 mt-3">
                          <label>Date Filter</label>
                          <button
                            className="calender btn btn-outline-danger"
                            onClick={handleDateFilterClick}
                            style={{
                              fontSize: "11px",
                              width: "215px",
                            }}
                          >
                            <MdDateRange
                              size={16}
                              style={{ display: "inline" }}
                            />{" "}
                            {`${format(
                              date.startDate,
                              "MMM dd yyyy"
                            )} -- ${format(date.endDate, "MMM dd yyyy")}`}
                          </button>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className=" modal-dialog modal-dialog modal-lg"
                              role="document"
                              style={{ marginTop: "80px" }}
                            >
                              <div
                                className="modal-content"
                                style={{ borderRadius: "8px" }}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <FilterComponent
                          leadSourceOptions={[{}, {}, {}]}
                          leadPipelineOptions={[{}, {}, {}]}
                          businessCategoryOptions={[{}, {}, {}]}
                          partnerOptions={[{}, {}, {}]}
                        />
                        <div className="d-flex">
                          <div className="mr-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-success"
                              onClick={handleApplyFilter}
                            >
                              Apply Filter
                            </button>
                          </div>
                          <div className="mx-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-warning"
                              onClick={() => {
                                setFilterByObj({
                                  leadSource: "",
                                  leadPipeline: "",
                                  businessCategory: "",
                                  shipmentPotential: "",
                                  clientPersona: "",
                                  partner: "",
                                  to: "",
                                  from: "",
                                  isKycDone: "",
                                  genericEmailPattern: "",
                                  employeeId: "",
                                  minimumExpectedShipmentToFship: "",
                                });
                                setDate({
                                  startDate: new Date(),
                                  endDate: new Date(),
                                  key: "selection",
                                });
                                // window.location.reload();
                              }}
                            >
                              Clear Filter
                            </button>
                          </div>
                          <div>
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-info"
                              onClick={handleOpenModal}
                            >
                              Export
                            </button>
                            <div
                              className={`modal ${
                                modelOpen ? "show" : ""
                              } bg-black-7`}
                              style={{
                                display: modelOpen ? "block" : "none",
                              }}
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered modal-xl"
                                role="document"
                              >
                                <div className="modal-content rounded-3">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Download Excel
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseModal}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>

                                  <div
                                    className="modal-body "
                                    style={{ width: "100%" }}
                                  >
                                    {from !== "" ? (
                                      <p>Click here to download</p>
                                    ) : (
                                      <p>
                                        Please wait , Download button will
                                        appear soon...{" "}
                                      </p>
                                    )}
                                    {from !== "" ? (
                                      <button
                                        className="btn ripple btn-primary"
                                        type="button"
                                        onClick={handleConfirm}
                                      >
                                        Download
                                      </button>
                                    ) : (
                                      <img src={spiner} height={"50px"}></img>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="d-flex">
                    <CardComponent
                      title={"Total Won Lead"}
                      link={"Total Won Lead"}
                      count={wonVsLost.wonLeadReport}
                      totalLead={wonVsLost.report}
                      percentage={wonVsLost.percentage}
                    />
                    <CardComponent
                      title={"Total Lost Lead"}
                      link={"Total Lost Lead"}
                      count={wonVsLost.wonLeadReport}
                      totalLead={wonVsLost.report}
                      percentage={wonVsLost.percentage}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Won Lead By Lead Source
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Lead Source</th>
                                <th scope="col">Total Leads</th>
                                <th scope="col">Won Leads</th>
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {wonLeadBySource.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Email">
                                    <span>{item.leadSource}</span>
                                  </td>
                                  <td data-label="Phone">
                                    <a
                                      class="text-current"
                                      href="mailto:robert.fox@example.com"
                                    >
                                      {item.totalLeads}
                                    </a>
                                  </td>
                                  <td data-label="Lead Score">
                                    <a
                                      class="text-current"
                                      href="tel:202-555-0152"
                                    >
                                      {item.wonLeads}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Won Lead By Lead State
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Lead State</th>
                                <th scope="col">Total Lead</th>
                                <th scope="col">Won Lead</th>
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {wonLeadByState.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Email">
                                    <span>{item.leadSource}</span>
                                  </td>
                                  <td data-label="Phone">
                                    <a
                                      class="text-current"
                                      href="mailto:robert.fox@example.com"
                                    >
                                      {item.totalLeads}
                                    </a>
                                  </td>
                                  <td data-label="Lead Score">
                                    <a
                                      class="text-current"
                                      href="tel:202-555-0152"
                                    >
                                      {item.wonLeads}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Won Lead By Per Sales Agent
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Sales Agent</th>
                                <th scope="col">Total Lead</th>
                                <th scope="col">Won Lead</th>
                                <th scope="col">Rate</th>
                              </tr>
                            </thead>
                            <tbody>
                              {wonleadByPerSalesAgent.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Email">
                                    <span>{item.employeeName}</span>
                                  </td>
                                  <td data-label="Phone">
                                    <a
                                      class="text-current"
                                      href="mailto:robert.fox@example.com"
                                    >
                                      {item.totalLeads}
                                    </a>
                                  </td>
                                  <td data-label="Lead Score">
                                    <a
                                      class="text-current"
                                      href="tel:202-555-0152"
                                    >
                                      {item.wonLeads}
                                    </a>
                                  </td>
                                  <td data-label="Lead Score">
                                    <a
                                      class="text-current"
                                      href="tel:202-555-0152"
                                    >
                                      {item.SalesConversionRates}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Row */}
              </div>

              {}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClosureReport;
