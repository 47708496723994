import React, { useState } from "react";

const FilterButtonComponent = ({
  handleClearFilter,
  handleOpenModal,
  handleCheck,
  handleApplyFilter,
}) => {
  const [disableGenericEmail, setDisableGenericEmail] = useState(false);

  // Handler for checkbox change in child
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setDisableGenericEmail(isChecked);
    handleCheck(isChecked); // Call parent handler to update filter in parent
  };
  //console.log(disableGenericEmail);
  return (
    <div
      style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}
    >
      <div style={{ marginTop: "10px" }}>
        <input
          type="checkbox"
          id="disableGenericEmail"
          name="disableGenericEmail"
          checked={disableGenericEmail}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="disableGenericEmail">Disable Generic Email</label>
      </div>

      <button
        style={{ marginLeft: "12px" }}
        onClick={handleApplyFilter}
        className="btn btn-outline-secondary"
      >
        Apply Filter
      </button>

      <button
        style={{ marginLeft: "12px" }}
        onClick={handleClearFilter}
        className="btn btn-outline-secondary"
      >
        Clear Filter
      </button>

      <button
        style={{ marginLeft: "12px" }}
        onClick={handleOpenModal}
        className="btn btn-outline-info"
      >
        Export
      </button>
    </div>
  );
};

export default FilterButtonComponent;
