import TableComponent from "./TableComponent";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./index2.css";
import { useParams } from "react-router-dom";
import TabAPIComponent from "./TabAPIComponent";
import Loader from "../../assets/img/loader.gif";

const Token = localStorage.getItem("Token");
const apiUrl = process.env.REACT_APP_URL;

// let num = 0;

function TabTableComponent({
  setModalOpen3,
  setModalOpen5,
  bulkAssignId,
  filterByObj,
  applyFilter,
  setBulkAssignId,
  setAssignedEmployeeId,
  setCaseId,
  setModalOpenAwb,
  setModalOpenOrderId,
  setStatusModalOpen,
  setModalOpenStatusChange,
  updateStatus,
  setModalOpenAssign,
  modalOpenOrderId,
  statusModalOPen,
  newStatus,
  setNewStatus,
  leadStatusOptions,
  handleCloseStatusModal,
  handleStageValue,
}) {
  // console.log("Aman ", num++);
  const navigate = useNavigate();
  const location = useLocation();
  const { pending } = location.state || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [loader, setLoader] = useState(false);
  const customerId = useParams();
  const { status } = useParams();
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [countCallAttempted, setCountCallAttempted] = useState(0);
  const [countFollowup, setCountFollowup] = useState(0);
  const [countLOST, setCountLOST] = useState(0);
  const [countconatactInFuture, setCountconatactInFuture] = useState(0);
  const [countnegotiation, setCountnegotiation] = useState(0);
  const [countproposal, setCountproposal] = useState(0);
  const [countOpen, setCountOpen] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const selectAllLead = document.getElementById("bulkSelect");
  const [linkActive, setLinkActive] = useState("In Progress");
  const [stageValue, setStageValue] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const isNumber = (status) => !isNaN(status);
  useEffect(() => {
    setCurrentPage(1);
  }, [status]);
  // export
  function containsPercentage(str) {
    return str.includes(" ");
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    scrollToTop();
  };
  const handlePageSize = (value) => {
    setPageSize(value.target.innerText);

    scrollToTop();
  };

  const fetchAllLead1 = (stage) => {
    // console.log(stage);
    // console.log(status);
    // stage ==  && setLoader(true);
    stage === undefined && setLoader(true);
    const url =
      window.location.href.includes("cases") &&
      window.location.href.includes("%")
        ? `${apiUrl}/caseM/caseList?status=${
            stage?.includes("Pending")
              ? stage
              : status === "All"
              ? ""
              : pending?.includes("Pending")
              ? pending
              : status
          }&caseType=${filterByObj.caseType}&caseSubType=${
            filterByObj.caseSubType
          }&customerId=${
            isNumber(customerId.status)
              ? customerId.status
              : filterByObj.customerId
          }&kamId=${filterByObj.kamId}&to=${filterByObj.to?.substring(
            0,
            10
          )}&from=${filterByObj.from?.substring(0, 10)}&search=${
            filterByObj.search
          }&page=${currentPage}&pageSize=${pageSize}`
        : `${apiUrl}/caseM/caseList?status=${
            isNumber(status) ? "" : status == "All" ? "" : status
          }&caseType=${filterByObj.caseType}&caseSubType=${
            filterByObj.caseSubType
          }&customerId=${
            isNumber(customerId.status)
              ? customerId.status
              : filterByObj.customerId
          }&kamId=${filterByObj.kamId}&to=${filterByObj.to?.substring(
            0,
            10
          )}&from=${filterByObj.from?.substring(0, 10)}&search=${
            filterByObj.search
          }&page=${currentPage}&pageSize=${pageSize}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setLoader(false);
          setStageValue(stage);
          handleStageValue(stage);
          setFilteredLeads(data.data);
          setLinkActive(stage ? stage : pending);
          setCountCallAttempted(data.allCounts.pendingOnTechCount);
          setCountFollowup(data.allCounts.pendingOnFirstMileOpsCount);
          setCountLOST(data.allCounts.pendingOnLastMileOpsCount);
          const totalCount = data.totalCount;
          setTotalPages(Math.ceil(totalCount / pageSize));
          setCountconatactInFuture(data.allCounts.pendingOnWeightteamCount);
          setCountLOST(data.allCounts.pendingOnLastMileOpsCount);
          setCountnegotiation(data.allCounts.pendingOnSalesCount);
          setCountproposal(data.allCounts.pendingOnLegalCount);
          setCountOpen(data.allCounts.pendingOnClientCount);
          setTotalCount(data.allCounts.inProcessCount);
          if (selectAllLead) {
            selectAllLead.checked = false;
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchAllLead1();
  }, [status, currentPage, pageSize, applyFilter]);

  return (
    <>
      <div className="col-lg-12">
        <div className="card custom-card">
          <div className="card-body">
            <div className="text-wrap">
              <div className="panel panel-primary tabs-style-3 p-0 tabs-style-3-0">
                <div className="tab-menu-heading">
                  <div className="tabs-menu ">
                    <ul
                      className={`nav panel-tabs ${
                        window.location.href.includes("cases")
                          ? "d-flex"
                          : "d-none"
                      }`}
                    >
                      {containsPercentage(status) ? <></> : <></>}{" "}
                      {containsPercentage(status) ? (
                        <TabAPIComponent
                          handleStatusChange1={fetchAllLead1}
                          linkActive={linkActive}
                          counts={{
                            countCallAttempted,
                            countFollowup,
                            countLOST,
                            countconatactInFuture,
                            countnegotiation,
                            countproposal,
                            countOpen,
                            totalCount,
                          }}
                        />
                      ) : null}
                      {bulkAssignId.length ? (
                        <a
                          // href=""
                          className="btn ripple btn-warning btn-sm m-2 text-center"
                          onClick={() => {
                            setModalOpen3(true);
                          }}
                          style={{ color: "#fff" }}
                        >
                          Bulk Status Change
                        </a>
                      ) : (
                        <></>
                      )}
                      {bulkAssignId.length ? (
                        <a
                          // href=""
                          className="btn ripple btn-secondary btn-sm m-2 text-center"
                          onClick={() => {
                            setModalOpen5(true);
                          }}
                          style={{ color: "#fff" }}
                        >
                          Bulk Case Assign
                        </a>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white m-3 rounded-3">
        <TableComponent
          bulkAssignId={bulkAssignId}
          setBulkAssignId={setBulkAssignId}
          filteredLeads={filteredLeads}
          status={status}
          // caseId={caseId}
          setCaseId={setCaseId}
          // // modalOpenAwb={modalOpenAwb}
          setModalOpenAwb={setModalOpenAwb}
          modalOpenOrderId={modalOpenOrderId}
          setModalOpenOrderId={setModalOpenOrderId}
          // // modalOpenAssign={modalOpenAssign}
          setModalOpenAssign={setModalOpenAssign}
          // assignedEmployeeId={assignedEmployeeId}
          setAssignedEmployeeId={setAssignedEmployeeId}
          //modalOpenStatusChange={modalOpenStatusChange}
          setModalOpenStatusChange={setModalOpenStatusChange}
          statusModalOPen={statusModalOPen}
          setStatusModalOpen={setStatusModalOpen}
          newStatus={newStatus}
          setNewStatus={setNewStatus}
          leadStatusOptions={leadStatusOptions}
          updateStatus={updateStatus}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handlePageSize={handlePageSize}
          handleCloseStatusModal={handleCloseStatusModal}
        />
      </div>
      {loader ? (
        <div className="loading-overlay">
          <img src={Loader} alt="Loading..." />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default TabTableComponent;
