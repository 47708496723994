import React, { useEffect, useState, useRef } from "react";
//import MainPage from "../../Components/MainPage";
import Prince from "../../../Components/Prince";
//import DropdownMenu from "../../Components/DropdownMenu";
import { Button } from "bootstrap";
import TopHeader from "../../../Components/TopHeader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
//import Prompt from "../../Components/Promt";
import { format } from "date-fns";
import { MdDateRange } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import spiner from "../../../assets/img/spiner.gif";
import Loader from "../../../assets/img/loader.gif";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ActivityFilterComponent from "../ReportComponent/ActivityFilterComponent";

// import Table from "react-bootstrap/Table";
const Token = localStorage.getItem("Token");
const apiUrl = process.env.REACT_APP_URL;
//console.log(apiUrl);

function ActivityReport() {
  const [fromDate, setFromDate] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [loader, setLoader] = useState(true);
  const [toDate, setToDate] = useState("");
  const [from, setFrom] = useState("");
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const { customerId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // console.log("Token:", Token);
  const [monthData, setMonthData] = useState([]);
  const [dropDownYear, setDropDownYear] = useState([]);
  const [search, setSearch] = useState("");
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [filterByObj, setFilterByObj] = useState({
    leadSource: "",
    leadPipeline: "",
    businessCategory: "",
    shipmentPotential: "",
    clientPersona: "",
    partner: "",
    to: "",
    from: "",
    search: "",
    isKycDone: "",
    minimumExpectedShipmentToFship: "",
    employeeId: "",
    genericEmailPattern: "",
  });

  // const years = [...new Set(dropDownYear.map((item) => item.year))]; // Assuming your data has a 'year' property
  function adjustDateAndExtractMonthYear(dateString) {
    // Parse the date string
    let date = new Date(dateString);

    // Add one day to the date
    date.setDate(date.getDate() + 1);

    // Extract the month and year
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11, so add 1 to get 1-12
    const year = date.getUTCFullYear();

    return { month, year };
  }

  const handleCustomerChange = (e) => {
    setSelectedYear(e.target.value);
    // setCustomerName(customerId);
  };
  // console.log(selectedYear);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    document.body.classList.add("modal-open");

    const url = window.location.href.includes("all-report")
      ? `${apiUrl}/customer/monthlyReportExport?customerId=${selectedYear}&from=${fromDate}&to=${toDate}`
      : `${apiUrl}/customer/monthlyReportExport?customerId=${
          customerId == undefined ? "" : customerId
        }&from=${fromDate}&to=${toDate}`;
    // IMPORT
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // console.log(data.data.fileURL);
          setFrom(data.data.fileURL);
        }
      });
  };

  const handleConfirm = () => {
    window.location.href = from;

    setIsModalOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const handleDateChange = (ranges) => {
    const formatToISOString = (date) => {
      return new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();
    };

    const startDate = new Date(ranges.selection.startDate);
    const endDate = new Date(ranges.selection.endDate);

    setToDate(formatToISOString(startDate));
    setFromDate(formatToISOString(endDate));
    setFilterByObj({
      ...filterByObj,
      to: formatToISOString(endDate),
      from: formatToISOString(startDate),
    });

    setDate(ranges.selection);
  };
  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };
  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const capitalizeFirstLetterOfEachWord = (str) => {
    return str?.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  function getMonthName(monthNumber) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Ensure monthNumber is within valid range
    if (monthNumber < 1 || monthNumber > 12) {
      return "Invalid month";
    }

    return months[monthNumber - 1];
  }
  const adjustFromDate = adjustDateAndExtractMonthYear(fromDate);
  const adjustToDate = adjustDateAndExtractMonthYear(toDate);
  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/customer/customerDropdown`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCustomerOptions(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/customer/customerList`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setCustomerData(data.data);
          // console.log(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  // console.log(customerData);
  function getReport() {
    setLoader(true);
    const url = window.location.href.includes("all-report")
      ? `${apiUrl}/customer/allShipmentReport?customerId=${selectedYear}&from=${fromDate}&to=${toDate}`
      : `${apiUrl}/customer/allShipmentReport?customerId=${
          customerId == undefined ? "" : customerId
        }&from=${fromDate}&to=${toDate}`;
    fetch(
      // `${apiUrl}/customer/allShipmentReport?customerId=${selectedYear}&from=${`${adjustFromDate.month} ${adjustFromDate.year}`}&to=${`${adjustToDate.month} ${adjustToDate.year}`}`,
      url,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the retrieved data here
        // setMonthData(data.data);
        // setDropDownYear(data.data.monthRecords);

        // console.log("Data:", data);
        setTimeout(() => {
          setLoaded(true);
        }, 800);
        setLoader(false);
      })
      .catch((error) => {
        // Handle errors here

        console.error("Error:", error);
      });
  }
  // console.log(monthData);
  function getYearList() {
    setLoader(true);
    fetch(
      `${apiUrl}/customer/allShipmentReport?customerId=${
        customerId == undefined ? "" : customerId
      }&from=${filterByObj.from}&to=${filterByObj.to}`,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the retrieved data here
        // setMonthData(data.data.monthRecords);
        setDropDownYear(data.data.monthRecords);
        // console.log("Data:", data);
        setTimeout(() => {
          setLoaded(true);
        }, 800);
        setLoader(false);
      })
      .catch((error) => {
        // Handle errors here

        console.error("Error:", error);
      });
  }
  useEffect(() => {
    getYearList();
  }, []);
  useEffect(() => {
    getReport();
  }, [fromDate, toDate, selectedYear, customerId]);

  const currentValue = window.location.href?.includes("all-report")
    ? selectedYear
    : customerId;
  // console.log(monthData);

  // function createData(name, calories, fat, carbs, protein) {
  //   return { name, calories, fat, carbs, protein };
  // }

  // const rows = [
  //   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  //   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  //   createData("Eclair", 262, 16.0, 24, 6.0),
  //   createData("Cupcake", 305, 3.7, 67, 4.3),
  //   createData("Gingerbread", 356, 16.0, 49, 3.9),
  // ];
  const handleDateChange1 = (ranges) => {
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    setFilterByObj({
      ...filterByObj,
      to: adjustedEndDate.toISOString(),
      from: adjustedStartDate.toISOString(),
    });

    setDate(ranges.selection);
  };

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/activityReport/activityReport?employeeId=&status=new&from=&to=`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Token}`,
            },
          }
        );

        const data = await response.json();
        setMonthData(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchApi();
  }, []);

  return (
    <div className="page">
      <TopHeader />
      <Prince />
      <div className="main-content side-content pt-0 m-0">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Activity Report
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a>Report</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Activity Report
                  </li>
                </ol>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div
                className="row "
                style={{ marginLeft: "0", marginRight: "0" }}
              >
                <div className="card custom-card">
                  <div className="card-body py-3">
                    <div className="row">
                      {" "}
                      {/* <div className="col-sm-6">
                        <label>Search Here</label>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                          aria-controls="example1"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setFilterByObj({
                              ...filterByObj,
                              search: e.target.value,
                            });
                          }}
                        />
                      </div> */}
                      {/* <div className="col-sm-2">
                    <label> By Lead Source</label>
                    <select
                      type="filter"
                      name="leadSource[]"
                      className="form-control form-select"
                      aria-controls="example1"
                      multiple
                      value={filterByLeadSource}
                      onChange={(e) => {
                        const selectedOptions = Array.from(
                          e.target.selectedOptions,
                          (option) => option.value.replace(/^,|(%20)/g, " ")
                        );
                        setFilterByLeadSource(selectedOptions);
                        setFilterByObj({
                          ...filterByObj,
                          leadSource: selectedOptions,
                        });
                      }}
                    >
                    
                      {leadSourceOptions.map((data) => {
                        return <option value={data.name}>{data.name}</option>;
                      })}
                    </select>
                  </div> */}
                      <div
                        // className="d-flex"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {" "}
                        {/* <div className="mt-1">Select Customer: </div>{" "} */}
                        {/* <div
                          style={{
                            position: "relative",
                            height: "20px",
                            top: "-20px",
                          }}
                        >
                          {" "}
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Customers
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={currentValue}
                              onChange={handleCustomerChange}
                              label="Customer"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {customerOptions.map((customer) => (
                                <MenuItem
                                  key={customer.id}
                                  value={customer.id}
                                  // id={customer.id}
                                >
                                  {customer.primaryContactName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>{" "}
                        </div> */}
                        <div className="mt-3">
                          {" "}
                          <div style={{ marginLeft: "12px" }}>Date Filter</div>
                          {/* <button
                            style={{
                              // marginLeft: "12px",
                              fontSize: "12px",
                              height: "20px",
                              marginTop: "8px",

                              // backgroundColor: "#032852",
                            }}
                            // className="calender btn btn-info"
                            onClick={handleDateFilterClick}
                            className="btn btn-light"
                          >{`${format(
                            date.startDate,
                            "MMM dd yyyy"
                          )} -- ${format(
                            date.endDate,
                            "MMM dd yyyy"
                          )}`}</button> */}
                          <div className="col-sm-2 mt-2">
                            {/* <label>Date Filter</label> */}
                            <button
                              className="calender btn btn-outline-danger"
                              onClick={handleDateFilterClick}
                              style={{
                                fontSize: "11px",
                                width: "215px",
                              }}
                            >
                              <MdDateRange
                                size={16}
                                style={{ display: "inline" }}
                              />{" "}
                              {`${format(
                                date.startDate,
                                "MMM dd yyyy"
                              )} -- ${format(date.endDate, "MMM dd yyyy")}`}
                            </button>
                            <div
                              className={`modal ${
                                dateFilterModalOPen ? "show" : ""
                              } bg-black-7`}
                              style={{
                                display: dateFilterModalOPen ? "block" : "none",
                              }}
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div
                                className=" modal-dialog modal-dialog modal-lg"
                                role="document"
                                style={{ marginTop: "80px" }}
                              >
                                <div
                                  className="modal-content"
                                  style={{ borderRadius: "8px" }}
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Select Date Range
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseDateFilterModal}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div
                                    className="modal-body"
                                    style={{ width: "100%" }}
                                  >
                                    <DateRangePicker
                                      ranges={[date]}
                                      onChange={handleDateChange1}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ActivityFilterComponent
                          leadSourceOptions={[{}, {}, {}]}
                          leadPipelineOptions={[{}, {}, {}]}
                          businessCategoryOptions={[{}, {}, {}]}
                          partnerOptions={[{}, {}, {}]}
                        />
                        <div style={{ height: "20px", marginTop: "45px" }}>
                          <button
                            style={{ marginLeft: "12px" }}
                            onClick={() => {
                              setDate({
                                startDate: new Date(),
                                endDate: new Date(),
                                key: "selection",
                              });
                              window.location.reload();
                            }}
                            className="btn btn-light"
                          >
                            Apply Filter
                          </button>
                        </div>
                        <div style={{ height: "20px", marginTop: "45px" }}>
                          <button
                            style={{ marginLeft: "12px" }}
                            onClick={() => {
                              setDate({
                                startDate: new Date(),
                                endDate: new Date(),
                                key: "selection",
                              });
                              window.location.reload();
                            }}
                            className="btn btn-light"
                          >
                            Clear Filter
                          </button>
                        </div>
                        <div style={{ height: "20px", marginTop: "45px" }}>
                          <button
                            style={{ marginLeft: "12px" }}
                            onClick={handleOpenModal}
                            className="btn btn-light"
                          >
                            Export
                          </button>
                          <div
                            className={`modal ${
                              isModalOpen ? "show" : ""
                            } bg-black-7`}
                            style={{ display: isModalOpen ? "block" : "none" }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-xl"
                              role="document"
                            >
                              <div className="modal-content rounded-3">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    download excel
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div
                                  className="modal-body "
                                  style={{ width: "100%" }}
                                >
                                  {from !== "" ? (
                                    <p>Click here to download</p>
                                  ) : (
                                    <p>
                                      Please wait , Download button will appear
                                      soon...{" "}
                                    </p>
                                  )}
                                  {from !== "" ? (
                                    <button
                                      className="btn ripple btn-primary"
                                      type="button"
                                      onClick={handleConfirm}
                                    >
                                      Download
                                    </button>
                                  ) : (
                                    <img src={spiner} height={"50px"}></img>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        className={`modal ${
                          dateFilterModalOPen ? "show" : ""
                        } bg-black-7`}
                        style={{
                          display: dateFilterModalOPen ? "block" : "none",
                        }}
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-xl"
                          role="document"
                        >
                          <div className="modal-content rounded-3">
                            <div className="modal-header">
                              <h5 className="modal-title">Select Date Range</h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCloseDateFilterModal}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>

                            <div
                              className="modal-body"
                              style={{ width: "100%" }}
                            >
                              <DateRangePicker
                                ranges={[date]}
                                onChange={handleDateChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div>
                          <button
                            style={{
                              marginLeft: "12px",
                              fontSize: "12px",
                              backgroundColor: "#032852",
                            }}
                            className="calender btn btn-info"
                            onClick={handleDateFilterClick}
                          >{`${format(
                            date.startDate,
                            "MMM,dd,yyyy"
                          )} -- ${format(
                            date.endDate,
                            "MMM,dd,yyyy"
                          )}`}</button>

                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            }`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-xl"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                    </div>
                    {/* {loader ? (
                      // <div className="col-lg-2">
                      //   <img src={Loader} height={"50px"}></img>
                      // </div>
                      <div className="loading-overlay">
                        <img src={Loader} alt="Loading..." />
                      </div>
                    ) : (
                      <></>
                    )} */}
                  </div>{" "}
                </div>
              </div>
            </div>
            {/* Row */}
          </div>
          {/* popup */}

          <div className="bg-white  rounded-3">
            {/* <div className="p-4 ">
              {" "}
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Client Info</TableCell>
                      <TableCell align="left">Client</TableCell>
                      <TableCell align="left">Source</TableCell>
                      <TableCell align="left">KAM</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerData?.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            component="p"
                            className="mb-0"
                            style={{ textAlign: "justify" }}
                          >
                            Company:{" "}
                            <b>
                              {capitalizeFirstLetterOfEachWord(row.companyName)}
                            </b>
                            <br />
                            Designation:{" "}
                            <b>
                              {capitalizeFirstLetterOfEachWord(
                                row.primaryContactDesignation
                              )}
                            </b>
                            <br />
                            Business Category: <b>{row.businessCategory}</b>
                            <br />
                            Current Shipping Partner:{" "}
                            <b>{row.currentShippingPartner}</b>
                            <br />
                            Shipment Potential: <b>{row.shipmentPotential}</b>
                            <br />
                            Min Expected Shipment to Fship:{" "}
                            <b>{row.minimumExpectedShipmentToFship}</b>
                            <br />
                            Client Persona: <b>{row.clientPersona}</b>
                            <br />
                            UTM:{" "}
                            <b>
                              {row.utmSource}-{row.utmMedium}-{row.utmCampaign}-
                              {row.utmTerm}-{row.utmContent}
                            </b>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            component="p"
                            className="mb-0"
                            style={{ textAlign: "justify" }}
                          >
                            <b>
                              {row.primaryContactName
                                ? row.primaryContactName
                                    .charAt(0)
                                    .toUpperCase() +
                                  row.primaryContactName.slice(1)
                                : ""}
                            </b>
                            <br />
                            Email: <b>{row.emailId}</b>
                            <br />
                            Phone: <b>{row.mobileNumber}</b>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            component="p"
                            className="mb-0"
                            style={{ textAlign: "justify" }}
                          >
                            Source:{" "}
                            <b>
                              {capitalizeFirstLetterOfEachWord(row.leadSource)}
                            </b>
                            <br />
                            Status:{" "}
                            <b>
                              {row.masterStatus?.charAt(0).toUpperCase() +
                                row.masterStatus?.slice(1)}
                            </b>
                            <br />
                            Stage:{" "}
                            <b>
                              {row.status?.charAt(0).toUpperCase() +
                                row.status?.slice(1)}
                            </b>
                            <br />
                            Pipeline:{" "}
                            <b>
                              {capitalizeFirstLetterOfEachWord(
                                row.leadPipeline
                              )}
                            </b>
                            <br />
                            <div>
                              Company ID: <b>{row.companyId}</b>
                            </div>
                            <div>
                              KYC: <b>{row.isKycDone ? "Yes" : "No"}</b>
                            </div>
                            <div>
                              Remark: <b>{row.remark}</b>
                            </div>
                            <div>
                              Added On:{" "}
                              <b>
                                {new Date(row.createdAt)
                                  .toISOString()
                                  .slice(0, 10)}
                              </b>
                            </div>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            component="div"
                            className="mb-0"
                            style={{ textAlign: "justify" }}
                          >
                            {row.kam && (
                              <div>
                                <b>
                                  {capitalizeFirstLetterOfEachWord(row.kam)}
                                </b>
                              </div>
                            )}
                          </Typography>
                        </TableCell>
                       
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div> */}

            <div
              style={{
                textAlign: "center",
                marginTop: "30px",
                backgroundColor: "#F7418F",
                paddingTop: "10px",
                color: "#fff",
                fontWeight: "600",
                paddingBottom: "8px",
              }}
            >
              <h3>Activity Report</h3>
            </div>

            {loaded ? (
              <>
                {/* <div className="table-responsive p-4">
                  <table className="table table-striped table-bordered table-hover text-nowrap mb-0  rounded-3 ">
                    <thead>
                      <tr>
                     
                        <th>ID</th>
                        <th>TOTAL ORDER</th>
                       
                        <th> TOTAL SHIPMENT</th>
                        <th>MONTH</th>
                        <th>YEAR</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {monthData.length == 0 ? (
                        <></>
                      ) : (
                        monthData.map((row) => (
                          <tr key={row.id}>
                          
                            <td>
                              <p>
                                ID-<b>{row.id}</b>
                              </p>
                            </td>

                            <td>
                              <p className="mb-0">
                                Total Order : <b>{row.totalOrder}</b>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                Total Shipment : <b>{row.totalShipment}</b>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                Month : <b>{getMonthName(row.month)}</b>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                Year : <b>{row.year}</b>
                              </p>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  {monthData.length == 0 ? (
                    <div className="text-center row m-5">
                      <h1>No Data Found</h1>
                    </div>
                  ) : (
                    <></>
                  )}
                </div> */}
                <div className="p-2 mt-3 mb-3">
                  {" "}
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">ID</TableCell>
                          <TableCell align="center">Employee</TableCell>
                          <TableCell align="center">Call Attempted</TableCell>
                          <TableCell align="center">New</TableCell>
                          <TableCell align="center">Closed Won SYTS</TableCell>
                          <TableCell align="center">
                            Integration Inprogress
                          </TableCell>
                          <TableCell align="center">Lost</TableCell>
                          <TableCell align="center">
                            Contact In Future
                          </TableCell>
                          <TableCell align="center">Follow Up</TableCell>
                          <TableCell align="center">Negotiation</TableCell>
                          <TableCell align="center">Proposal</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {monthData.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">{row.id}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts["call-attempted"]}
                            </TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts.new}
                            </TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts["closed-won-SYTS"]}
                            </TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts["integration-inprogress"]}
                            </TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts.lost}
                            </TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts["contact-in-future"]}
                            </TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts["follow-up"]}
                            </TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts.negotiation}
                            </TableCell>
                            <TableCell align="center">
                              {row.leadStatusCounts.proposal}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            ) : (
              <></> // <h1 className="text-center">Loading...</h1>
            )}

            {}
          </div>
        </div>
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="">Fship</a>. Developed by{" "}
                  <a href="http://fship.in/">FSHIP</a> All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityReport;
